<template>
  <div id="affiliate-program">
    <div
      v-show="!clearedLoading"
      class="loading-container flex items-center justify-center opacity-100"
    >
      <loader :active="!clearedLoading" :message="loadingMessage"></loader>
    </div>
    <!-- <affiliate-search
      v-show="clearedLoading && !hasDefaultLocation"
      v-model:primaryColor="primaryColor"
    /> -->
    <section id="affiliateMain" v-show="clearedLoading && hasDefaultLocation">
      <ul class="navbar-affiliate">
        <li class="navbar-item">          
          <Popper
            :hover="true"
            arrow
            :interactive="false"
            @open:popper="openPopperHandler"
            @close:popper="closePopperHandler"            
          >
            <div class="guarantee" @click="openModalHandler">
              <span>Best rate guarantee</span>
            </div>
            <template #content>
              <div v-html="guaranteePopup"></div>
            </template>
          </Popper>          
        </li>
        <li class="navbar-item currency">
          <Popper
            arrow
            :interactive="true"
            :class="currencyClass"
            @open:popper="openCurrencyHandler"
            @close:popper="closeCurrencyHandler"
          >
            <a
              href="javascript:void(0)"
              class="btn btn-primary"
              title="Choose your currency"
              id="currency-link"
              ref="currencyButton"
            >
              <i class="currency" v-html="currentIsoCode"></i>
            </a>
            <template #content>
              <div class="currency-data">
                <div class="row" v-if="currencyData.success">
                  <div
                    v-if="currencyData.colTotal.popular > 0"
                    class="col-md-12 mb-2"
                  >
                    <strong>Top Currencies</strong>
                  </div>
                  <ul
                    v-for="(columns, index) in popularCurrencyColumns"
                    :key="'popular-' + index"
                    class="currency-list col-md-3 col-xs-12"
                  >
                    <li
                      v-for="(currency, idx) in columns"
                      :key="'currency-' + idx"
                      :id="'currency-' + currency.iso_code"
                      :class="
                        currencyData.selectedCurrency === currency.iso_code
                          ? 'selected'
                          : ''
                      "                      
                    >
                      <a 
                        href="javascript:void(0)" 
                        class="currency-selection"
                        @click="selectCurrency(currency.iso_code, $event)"
                        >
                        <span class="curSymbol pull-left"
                          v-html="currency.unicode_symbol"></span>
                        <span class="curName pull-left">{{
                          currency.name
                        }}</span>
                        <span v-if="currency.iso_code === pendingIsoCode" class="loading"></span>
                      </a>
                    </li>
                  </ul>
                  <div
                    v-if="currencyData.colTotal.all > 0"
                    class="col-xs-12 m-2"
                  >
                    <strong>All Currencies</strong>
                  </div>
                  <ul
                    v-for="(columns, index) in allCurrencyColumns"
                    :key="'all-' + index"
                    class="currency-list col-md-3 col-xs-12"
                  >
                    <li
                      v-for="(currency, idx) in columns"
                      :key="'currency-' + idx"
                      :id="'currency-' + currency.iso_code"
                      :class="
                        currencyData.selectedCurrency === currency.iso_code
                          ? 'selected'
                          : ''
                      "                      
                    >
                      <a 
                        href="javascript:void(0)" 
                        class="currency-selection"
                        @click="selectCurrency(currency.iso_code, $event)"
                        >
                        <span class="curSymbol pull-left"
                          v-html="currency.unicode_symbol"></span>
                        <span class="curName pull-left">{{
                          currency.name
                        }}</span>
                        <span v-if="currency.iso_code === pendingIsoCode" class="loading"></span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="row" v-else>
                  <div class="col-md-12">
                    <strong v-html="currencyHtml"></strong>
                  </div>
                </div>
              </div>
            </template>
          </Popper>
        </li>
      </ul>
      <tabs
        ref="tabsController"
        @clicked="tabClicked"
        @changed="tabChanged"
        cache-lifetime="0"
        :options="{ disableScrollBehavior: true }"
      >
        <tab id="tab1" 
          :name="searchResults"
        >
          <div ref="container"></div>          
        </tab>
        <tab
          id="tab2"
          :name="currentAccommodation"
          :suffix="badgeCounter"
          ref="accomContainer"
          :is-disabled="tabs.accommodation"
        >
          <div
            v-show="accommodationCurrentlyShowing === 0"
            ref="accommodation0"
          ></div>
          <div
            v-show="accommodationCurrentlyShowing === 1"
            ref="accommodation1"
          ></div>
          <div
            v-show="accommodationCurrentlyShowing === 2"
            ref="accommodation2"
          ></div>
          <div
            v-show="accommodationCurrentlyShowing === 3"
            ref="accommodation3"
          ></div>
          <div
            v-show="accommodationCurrentlyShowing === 4"
            ref="accommodation4"
          ></div>
          <div
            v-show="accommodationCurrentlyShowing === 5"
            ref="accommodation5"
          ></div>
          <div
            v-show="accommodationCurrentlyShowing === 6"
            ref="accommodation6"
          ></div>
          <div
            v-show="accommodationCurrentlyShowing === 7"
            ref="accommodation7"
          ></div>
          <div
            v-show="accommodationCurrentlyShowing === 8"
            ref="accommodation8"
          ></div>
          <div
            v-show="accommodationCurrentlyShowing === 9"
            ref="accommodation9"
          ></div>
          <div
            v-show="accommodationCurrentlyShowing === 10"
            ref="accommodation10"
          ></div>
        </tab>
        <tab id="tab3" name="Checkout!" :is-disabled="tabs.book">
          <div ref="booking"></div>
        </tab>
      </tabs>
      <info-modal
        :show="showModal"
        :title="modalTitle"
        :titleColor="primaryColor"
        :message="guaranteeText"
        @close="closeModal"
      ></info-modal>
    </section>
  </div>
</template>

<script>
import { getCurrentInstance, toRaw } from "vue";
import { Tabs, Tab } from "vue3-tabs-component";
import Loader from "./Loader.vue";
import InfoModal from "./InfoModal.vue";
import Popper from "vue3-popper";
import { createToaster } from "@meforma/vue-toaster";
import { affiliateAPISettings, affiliateConfig } from "../api/affiliateConfig";
import renderComponent from "../plugins/renderComponent";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "AffiliateMain",
  components: {
    Popper,
    Loader,
    InfoModal,
    Tabs,
    Tab,
  },
  props: {
    nonce: String,
  },
  data() {
    return {
      loadingMessage: "",
      clearedLoading: false,
      profileData: null,
      locationSlug: "",
      dynamicLabel: "Loading ...",
      textColor: "#fff",
      uid: "",
      errorType: {
        Critical: 0,
        Info: 1,
      },
      filterFill: "none",
      filterStroke: "black",
      showModal: false,
      modalTitle: "Terms &#38; Conditions",
      guaranteePopup: "",
      guaranteeText: "",
      showCurrency: false,
      currencyClass: "largePopper",
      currencyHtml: "&nbsp;Loading&nbsp;...&nbsp;",
      currentIsoCode: "&#8364;&#163;&#36;",
      hotelIsoCode: "&#8364;&#163;&#36;",
      currencyData: {
        currencies: [],
        popular: [],
        colTotal: {
          all: 0,
          popular: 0,
        },
        colCount: 4,
        selectedCurrency: "&#8364;&#163;&#36;",
        success: false,
      },
      pendingIsoCode: '',
      currencyUpdating: false,
      toaster: null,
      toastOptions: {
        position: "top-left",
      },
      tabs: {
        accommodation: false,
        book: false,
      },
      currentTabs: 0,      
      components: {
        search: null,
        accommodation: [],
        booking: null,
      },
      filterOpen: false,
      searchComponentProps: {},
      myAppContext: null,
      accommodationFrames: [],
      accommodationCurrentlyShowing: 0,
      facilityNames: [],
    };
  },
  computed: {
    primaryColor: function () {
      // `this` points to the vm instance
      return this.profileData ? this.profileData.primary_color : null;
    },
    hasDefaultLocation: function () {
      return this.profileData ? this.profileData.boundary : false;
    },
    searchResults: function () {
      var searchResultsLabel = '<div class="flex flex-row">';
      searchResultsLabel += '<div class="basis-5"><img src="/resource/images/icon-search.png" alt="search-icon" width="20" />';       
      searchResultsLabel += '<span class="pl-15 default-label">Search Results</span>';
      searchResultsLabel += '<span class="pl-15 mobile-view">' + this.dynamicLabel + '</span>';
      searchResultsLabel += '</div>';       
      searchResultsLabel += '</div>';
      return searchResultsLabel;
    },
    currentAccommodation: function () {
      var accommodationLabel = "Accommodations";
      if (this.accommodationFrames.length) {
        accommodationLabel =
          "<select class='open-tabs' @[eventname]='changeHandler($event)'>";
        let i = 0;
        for (let name of this.facilityNames) {
          accommodationLabel += "<option";
          if (
            this.accommodationFrames[i].tabIndex ===
            this.accommodationCurrentlyShowing
          ) {
            accommodationLabel += " selected ";
          }
          accommodationLabel += ">" + name + "</option>";
          i++;
        }
        accommodationLabel += "</select>";
        accommodationLabel +=
          "<span class='close-tab' title='Close Tab'><img src='/resource/images/icon-x.png' alt='close Tab' /></span>";
      }
      return accommodationLabel;
    },
    badgeCounter: function () {
      var suffixHtml = "";
      if (this.accommodationFrames.length > 1) {
        suffixHtml += "<div class='suffix'>";
        suffixHtml +=
          "<span class='badge' title='" +
          this.accommodationFrames.length +
          " tabs open'";
        if (this.primaryColor)
          suffixHtml += " style='background-color: " + this.primaryColor + ";'";
        suffixHtml += "> " + this.accommodationFrames.length + " </span></div>";
      }
      return suffixHtml;
    },
    popularCurrencyColumns: function () {
      var columns = [];
      var popularTotal = this.currencyData.popular.length;
      var popularCurrencies = toRaw(this.currencyData.popular);
      var idx = 0;
      for (var i = 0; i < this.currencyData.colCount; i++) {
        var currencies = [];
        var j = 0;
        while (j < this.currencyData.colTotal.popular && idx < popularTotal) {
          currencies.push(popularCurrencies[idx++]);
          j++;
        }
        columns.push(currencies);
      }
      return columns;
    },
    allCurrencyColumns: function () {
      var columns = [];
      var allTotal = this.currencyData.currencies.length;
      var allCurrencies = toRaw(this.currencyData.currencies);
      var idx = 0;
      for (var i = 0; i < this.currencyData.colCount; i++) {
        var currencies = [];
        var j = 0;
        while (j < this.currencyData.colTotal.all && idx < allTotal) {
          currencies.push(allCurrencies[idx++]);
          j++;
        }
        columns.push(currencies);
      }
      return columns;
    },
  },
  created() {
    const vm = this;
    setTimeout(function () {
      try {
        if (
          "xprops" in window === false ||
          "clientId" in window.xprops === false
        ) {
          throw new vm.systemException(
            vm.errorType.Critical,
            "Affiliate Plugin is either not setup properly or you need to reload the page, closing"
          );
        }
        console.log(window.xprops.clientId);
        const getAffiliateProfileUrl =
          affiliateAPISettings.affiliateProgramURL + "setup";
        console.log(getAffiliateProfileUrl);
        const postData = JSON.stringify({
          uid: window.xprops.clientId,
          hostname: window.xprops.getParentDomain(),
        });
        console.log(postData);
        fetch(getAffiliateProfileUrl, {
          method: "POST",
          body: postData,
          headers: affiliateAPISettings.headers,
        })
          .then(async (response) => {
            console.log("INSIDE requestAffiliateSetup");
            let affiliateProfile = await response.json();
            console.log(response);
            if (response.status === 200) {
              console.log(affiliateProfile.data);
              console.log(affiliateProfile.content);
              vm.profileData = affiliateProfile.data;
              vm.dynamicLabel =  vm.profileData.boundary.association.name + 
                ', ' + vm.profileData.searchboundaryrestrictions[0].boundary.association.name;
              vm.uid = affiliateProfile.data.identifier;
              if (vm.hasDefaultLocation) {
                vm.initiateRoomsFrame();
              }
              if (affiliateProfile.content.length) {
                console.log(affiliateProfile.content);
                for (let i = 0; i < affiliateProfile.content.length; i++) {
                  for (let [key, value] of Object.entries(
                    affiliateProfile.content[i]
                  )) {
                    vm[vm.camelCase(key)] =
                      (vm.camelCase(key) === "guaranteeText" ? "<small>" : "") +
                      value +
                      (vm.camelCase(key) === "guaranteeText" ? "<small>" : "");
                  }
                }
              }
              vm.clearedLoading = true;
            } else {
              vm.loadingMessage = affiliateProfile.error;
            }
          })
          .catch((error) => {
            console.log(error);
            vm.loadingMessage = error;
          });
      } catch (systemException) {
        vm.loadingMessage = systemException.message;
        if (systemException.type === vm.errorType.Critical) {
          setTimeout(function () {
            window.xprops.close();
          }, 1500);
        } else if (systemException.type === vm.errorType.Info) {
          setTimeout(function () {}, 1500);
        }
      }
    }, 500);
  },
  mounted() {
    const vm = this;
    console.log("AFFILIATE MAIN MOUNTED!!", vm.nonce);
    // Initializations
    this.toaster = createToaster(this.toastOptions);
    const { appContext } = getCurrentInstance();
    console.log(appContext);
    this.myAppContext = appContext;
    vm.tabs.accommodation = true;
    vm.tabs.book = true;

    const getCurrencyAllUrl =
      affiliateAPISettings.baseURL + "/api/currency/all?product=affiliate";
    fetch(getCurrencyAllUrl, {
      method: "GET",
      headers: affiliateAPISettings.headers,
    })
      .then(async (response) => {
        let currencyData = await response.json();
        console.log(response);
        if (response.status === 200) {
          console.log(currencyData);
          vm.currencyData = currencyData;
          vm.currentIsoCode = vm.currencyData.selectedCurrency === 'hotel' ? vm.hotelIsoCode : vm.currencyData.selectedCurrency;          
        } else {
          vm.loadingMessage = currencyData.error;
        }
      })
      .catch((error) => {
        console.log(error);
        vm.loadingMessage = error;
      });

    this.emitter.on("iframe-search-event", (evt) => {
      console.log("INSIDE iframe-search-event INSERT");
      console.log(evt.eventContent);
      if (evt.eventContent.action === "update") {
        vm[evt.eventContent.field] = evt.eventContent.value;
        // vm.filterFill = vm.filterOpen ? "green" : "none";
      } else if (evt.eventContent.action === "insert") {
        if (this.accommodationFrames.length === 0) {
          vm.tabs.accommodation = false; // Enable tab
        }
        // Check if iframe already exists with these parameters
        var accomIndex = 0;
        var doesAccomExist = vm.accommodationFrames.some((spec, index) => {
          accomIndex = index;
          return (
            spec.locationSlug === evt.eventContent.locationSlug &&
            spec.facilityId === evt.eventContent.facilityId
          );
        });

        if (doesAccomExist) {
          vm.accommodationCurrentlyShowing =
            vm.accommodationFrames[accomIndex].tabIndex;
        } else {
          // Check if we reached our maximum tabs
          if (this.accommodationFrames.length >= affiliateConfig.maxAccommodationTabs) {
            this.emitter.emit("affiliate-rooms-component", {
              eventContent: {
                action: "update",
                field: "message",
                value:
                  "Please close out some of your accommodation tabs (max. of " +
                  affiliateConfig.maxAccommodationTabs +
                  ")",
                facilityId: parseInt(evt.eventContent.facilityId),
              },
            });
            return false;
          }

          vm.retrieveFacilityName(evt.eventContent.facilityId);
          var componentId = this.generateUniqueId();
          evt.eventContent.componentId = componentId;

          vm.currentTabs++;
          console.log("INSIDE AffiliateMain: "+vm.currencyData.selectedCurrency);
          const componentProps = {
            ...evt.eventContent,
            componentId: componentId,
            tabs: vm.currentTabs,          
          };
          console.log(componentProps);
          // TODO: the refName needs to be determined from the empty refs
          // tabIndex should really be refIndex
          const refIndex = this.accommodationFrames.length
            ? vm.determineRefIndexForNewTab()
            : 0;
          console.log("refIndex = ", refIndex);
          const accommodationFrameObject = {
            ...componentProps,
            tabIndex: refIndex,
          };
          vm.accommodationFrames.push(accommodationFrameObject);
          console.log(vm.accommodationFrames);
          vm.accommodationCurrentlyShowing = refIndex;

          this.emitter.emit("affiliate-rooms-component", {
            eventContent: {
              action: "update",
              field: "tabs",
              value: parseInt(vm.currentTabs),
            },
          });
          const refName = "accommodation" + refIndex;
          let ele = vm.$refs[refName];
          console.log(ele);
          vm.insert(
            "accommodation",
            this.myAppContext,
            ele,
            "AffiliateRoomCreate",
            componentProps
          );
        }
        console.log("CHANGING ACTIVE TAB TO ACCOMMODATION");
        vm.$refs.tabsController.selectTab("#tab2");

      }
    });
    this.emitter.on("iframe-book-event", (evt) => {
      console.log("INSIDE iframe-book-event");
      console.log(evt.eventContent);
      vm.tabs.book = false; // Enable tab
      const refName = "booking";
      let ele = vm.$refs[refName];
      if (vm.components.booking === null) {
        vm.insert(
          "booking",
          this.myAppContext,
          ele,
          "AffiliateBookCreate",
          evt.eventContent
        );
      } else {
        this.emitter.emit("affiliate-book-component", {
          eventContent: {
            ...evt.eventContent,
            action: "update",
          },
        });
      }
      vm.$refs.tabsController.selectTab("#tab3");
    });
  },
  watch: {
    currentAccommodation: {
      async handler() {
        await this.$nextTick();
        console.log("INSIDE currentAccommodation watcher", this.$refs);
        document
          .querySelectorAll(".open-tabs")
          .forEach((select) =>
            select.addEventListener("change", this.changeHandler)
          );
        document
          .querySelectorAll(".close-tab")
          .forEach((close) =>
            close.addEventListener("click", this.closeHandler)
          );
      },
      immediate: true,
    },
    searchResults: {
      async handler() {
      },
      immediate: true,
    },
  },
  methods: {
    systemException(type, message) {
      this.type = type;
      this.message = message;
    },
    initiateRoomsFrame() {
      console.log("INSIDE initialRoomsFrame: ", this.profileData);
      console.log(this.profileData.boundary.association.identifier);
      this.locationSlug = this.profileData.boundary.association.identifier;
      this.uid = this.profileData.identifier;
      this.searchComponentProps = {
        locationSlug: this.locationSlug,
        uid: this.uid,        
      };
      this.insert(
        "search",
        this.myAppContext,
        this.$refs["container"],
        "AffiliateRoomsCreate",
        this.searchComponentProps
      );
    },
    generateUniqueId() {
      return uuidv4();
    },
    toggleFilter() {
      console.log("INSIDE toggleFilter");
      this.filterOpen = !this.filterOpen;
      console.log(this.filterOpen);
      this.filterFill = this.filterOpen ? "green" : "none";
      this.emitter.emit("affiliate-rooms-component", {
        eventContent: {
          action: "update",
          field: "filters",
          value: this.filterOpen,
        },
      });
    },
    openPopperHandler() {
      const ele = document.querySelector(".tabs-component-panels");
      ele.style.zIndex = -1;
      const tabs = document.querySelectorAll(".tabs-component-tab");
      tabs.forEach((tab) => {
        if (!tab.classList.contains("is-active")) {
          tab.style.zIndex = -2;
        }
      });
    },
    closePopperHandler() {
      if (!this.showCurrency) {
        const ele = document.querySelector(".tabs-component-panels");
        ele.style.zIndex = 0;
        const tabs = document.querySelectorAll(".tabs-component-tab");
        tabs.forEach((tab) => {
          if (!tab.classList.contains("is-active")) {
            tab.style.zIndex = null;
          }
        });
      }
    },
    toggleShowCurrency() {
      this.showCurrency = !this.showCurrency;
    },
    openCurrencyHandler() {
      console.log("INSIDE openCurrencyHandler");
      const ele = document.querySelector(".tabs-component-panels");
      ele.style.zIndex = -1;
      ele.style.position = "relative";
      const tabWrapper = document.querySelector(".tabs-component-tabs");
      tabWrapper.style.zIndex= -1;
      tabWrapper.style.position = "relative";
      const tabs = document.querySelectorAll(".tabs-component-tab");
      tabs.forEach((tab) => {
        // if (!tab.classList.contains("is-active")) {
          tab.style.zIndex = -2;
        // }
      });
      const panels = document.querySelectorAll(".tabs-component-panel");
      panels.forEach((panel) => {
        panel.style.zIndex = -2;
        panel.style.position = "relative";
      });
      this.showCurrency = true;
    },
    closeCurrencyHandler() {
      console.log("INSIDE closeCurrencyHandler");
      this.showCurrency = false;
      const tabWrapper = document.querySelector(".tabs-component-tabs");
      tabWrapper.style.zIndex= 0;
      const ele = document.querySelector(".tabs-component-panels");
      ele.style.zIndex = 0;
      const tabs = document.querySelectorAll(".tabs-component-tab");
      tabs.forEach((tab) => {
        // if (!tab.classList.contains("is-active")) {
          tab.style.zIndex = null;
        // }
      });
    },
    async http(url, method = 'GET', data) {
      try {
        const response = await fetch(url, {
          method: method,
          body: data,
          headers: affiliateAPISettings.headers,
        });
      
        return await response.json();
      } catch (error) {
        throw error;
      }
    },
    selectCurrency(isoCode, event) { 
      const vm = this;
      const $target = event.currentTarget;
      console.log(vm.currencyData.selectedCurrency + ' VS ' + isoCode);
      if (vm.currencyData.selectedCurrency !== isoCode && !vm.currencyUpdating) {
        console.log("Add loading spinner");
        vm.pendingIsoCode = isoCode;
        vm.currencyUpdating = true;
        const getCurrencyUserupdateUrl =
          affiliateAPISettings.baseURL + "/api/currency/userupdate?product=affiliate";
        vm.http(getCurrencyUserupdateUrl, 
          "POST", 
          JSON.stringify({ 'iso_code': isoCode }))
        .then((response) => {
          vm.pendingIsoCode = '';
          vm.currencyUpdating = false;
          if (response.success === true) {                        
            const previousIsoCode = (vm.currentIsoCode === vm.hotelIsoCode ? 'hotel' : vm.currentIsoCode);
            const liPreviousElement = document.getElementById('currency-'+previousIsoCode);
            liPreviousElement.classList.remove('selected');
            const liCurrentElement = document.getElementById('currency-'+isoCode);
            liCurrentElement.classList.add('selected'); 
            vm.currentIsoCode = (isoCode === 'hotel' ? vm.hotelIsoCode : isoCode);           
            vm.currencyData.selectedCurrency = isoCode;

            vm.emitter.emit("affiliate-rooms-component", {
              eventContent: {
                action: "update",
                field: "currency",
                value: isoCode,                
              },
            });

            for (let i = 0; i < this.accommodationFrames.length; i++) {
              let componentId = this.accommodationFrames[i].componentId;
              vm.emitter.emit("affiliate-room-" + componentId + "-event", {
                eventContent: {
                  action: "update",
                  field: "currency",
                  value: isoCode
                }
              });
            }

            if (vm.components.booking !== null) {
              console.log('Sending message to affiliate-book-component');
              vm.emitter.emit("affiliate-book-component", {
                eventContent: {                  
                  action: "reload",
                },
              });
            } 

            vm.$refs.currencyButton.click();
          } else {
            // vm.loadingMessage = currencyData.error;
          }
        })
        .catch((error) => {
          console.log(error);
          // vm.loadingMessage = error;
        });
      }
        
    },
    openModalHandler() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    changeHandler(event) {
      console.log("INSIDE onFacilityChange", event);
      console.log(event.target.selectedIndex);
      this.accommodationCurrentlyShowing =
        this.accommodationFrames[event.target.selectedIndex].tabIndex;
    },
    closeHandler() {
      const vm = this;
      const closeIndex = this.accommodationCurrentlyShowing;
      console.log("INSIDE onFacilityClose", closeIndex);
      const frameIndex = this.accommodationFrames.findIndex((frame) => {
        return frame.tabIndex === closeIndex;
      });
      var componentId = this.accommodationFrames[frameIndex].componentId;
      this.emitter.emit("affiliate-room-" + componentId + "-event", {
        eventContent: "close",
      });

      this.facilityNames.splice(frameIndex, 1);
      this.accommodationFrames.splice(frameIndex, 1);

      if (closeIndex < this.currentTabs - 1) {
        this.accommodationCurrentlyShowing =
          this.accommodationFrames[frameIndex].tabIndex;
      } else if (this.accommodationFrames.length) {
        this.accommodationCurrentlyShowing =
          this.accommodationFrames[
            this.accommodationFrames.length - 1
          ].tabIndex;
      } else {
        this.accommodationCurrentlyShowing = 0;
      }

      console.log("Rooms open: ", this.accommodationFrames.length);
      console.log(this.accommodationFrames);
      vm.currentTabs--;
      if (this.accommodationFrames.length === 0) {
        console.log("Switching to search results");
        this.$refs.tabsController.selectTab("#tab1");
        this.tabs.accommodation = true;
      }
      setTimeout(function () {
        console.log("AFTER 1000 timeout: ", vm.components.accommodation);
        vm.components.accommodation[frameIndex]?.();
        vm.components.accommodation.splice(frameIndex, 1);
      }, 1000);
    },
    determineRefIndexForNewTab() {
      // Loop through accommodationFrames, creating an array of tabIndexes
      let tabIndexes = this.accommodationFrames.map((frame) => frame.tabIndex);
      let i = 0;
      let missing = null;
      // Determine the next refIndex for the new tab
      while (i < tabIndexes.length && missing === null) {
        console.log("indexOf = ", tabIndexes.indexOf(i));
        if (tabIndexes.indexOf(i) === -1) {
          console.log("MISSING: ", i);
          missing = i;
        }
        i++;
      }
      return missing === null ? i : missing;
    },
    camelCase(string) {
      return string.replace(/-([a-z])/gi, function (all, letter) {
        return letter.toUpperCase();
      });
    },
    async retrieveFacilityName(facilityId) {
      const vm = this;
      const getFacilityNameUrl =
        affiliateAPISettings.facilityNameURL + facilityId + "/name";
      fetch(getFacilityNameUrl, {
        method: "GET",
        headers: affiliateAPISettings.headers,
      })
        .then(async (response) => {
          let facility = await response.json();
          if (response.status === 200) {
            console.log(facility);
            vm.facilityNames.push(facility.data);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async insert(
      componentRef,
      myAppContext,
      element,
      componentName,
      componentProps
    ) {
      console.log("INSIDE async insert", componentName);
      let renderedComponent = renderComponent({
        el: element,
        component: (await import("./" + componentName + ".vue")).default,
        props: componentProps,        
        myAppContext,
      });
      if (componentRef === "accommodation") {
        this.components[componentRef].push(renderedComponent);
      } else {
        this.components[componentRef] = renderedComponent;
      }
    },
  },
  updated() {
    console.log("*** INSIDE onUpdated ***");
    const { appContext } = getCurrentInstance();
    this.myAppContext = appContext;
  },
  unmounted() {
    this.searchComp?.();
    for (let i in this.accommodationComps) {
      this.accommodationComps[i]?.();
    }
    this.bookComp?.();
  },
};
</script>

<style lang="scss" scoped>
:deep(.dp__input) {
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='26' viewBox='0 0 8 18'%3E%3Cpath fill='%239599AA' fill-rule='nonzero' d='M.119.718l7.158 7.407-.033-.551-6.737 8.889a.425.425 0 0 0 .08.593.42.42 0 0 0 .59-.08l6.737-8.889a.425.425 0 0 0-.033-.551L.723.128A.42.42 0 0 0 .128.12a.425.425 0 0 0-.009.598z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 4px;
}

:deep(.dp__input_icon) {
  left: 10px;
}
:deep(.dp__clear_icon) {
  right: 10px;
}

:deep(.dp__clear_icon .input-slot-image) {
  width: 12px;
  height: 12px;
}

:deep(.suffix) {
  position: absolute;
  right: -0.725em;
  top: -0.725em;
}

:deep(.tabs-component-tabs) {
  padding-left: 0;
}

:deep(.open-tabs) {
  width: 85%;
  max-width: calc(100% - 2.5em);
  padding: 5px;
  float: left;
}

:deep(.close-tab) {
  float: right;
  position: relative;
  top: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

:deep(.close-tab:hover) {
  background-color: #d3d3d3;
}

:deep(.close-tab) > img {
  position: relative;
  top: 5px;
  width: 15px;
  height: 15px;
}

#affiliateMain {
  position: relative;
  .navbar-affiliate {
    display: none;
    list-style-type: none;
    padding: 0;
    position: absolute;
    z-index: 0;    
    margin-left: 0.8em;
    width: 286px;
    .navbar-item {
      display: inline-block;
      padding-right: 15px;
      .guarantee {
        margin-top: 0px;
        width: 120px;
        line-height: 1.1;
        background: url("/resource/images/icon_tick.png") no-repeat left center;
        background-size: 20px 20px;
        padding-left: 25px;
        cursor: pointer;
        span {
          font-size: 1em;
          font-weight: bold;
        }
      }
      #currency-link {
        margin-bottom: 7px;
        padding: 6px 16px;
      }
    }
    .navbar-item.currency {
      float: right;
    }
  }
}

:deep(.tabs-component) {
  margin: 0;
}

:deep(.tabs-component-tab .is-disabled) {
  display: none;
}

:deep(.tabs-component-tab-a) {

  .flex-row {
    justify-content: space-around;
    align-items: center;
  }

  .flex {
    display: flex;

    .basis-1: {
      flex-grow: 1;
    }

    .basis-4: {
      flex-grow: 4;
    }

    .basis-5: {
      flex-grow: 5;
    }
  }

  .pl-15 {
    padding-left: 0.375rem; 
  }

  .default-label {
    display: none;
  }

  .toggle-filter {
    display: block;
    height: 25px;
    width: 25px;
    img.active {
      background-color: green;
    }
  }
}

.largePopper {
  :deep(.popper) {
    background-color: #fff;
    min-width: 1168px;
    min-height: 250px;
    z-index: 9998 !important;
    color: #333;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);

    .currency-data {
      padding: 10px;

      .mb-2 {
        margin-bottom: 1em;
      }

      .m-2 {
        margin-top: 1em;
        margin-bottom: 1em;
      }

      .currency-list {
        float: left;
        padding: 0;
      }

      ul.currency-list li {
        display: inline-block;
        list-style-type: none;
        width: 90%;
        padding: 2px 5%;
      }

      ul.currency-list li:hover,
      .currency-data ul.currency-list li.selected {
        background-color: #eee;
      }

      ul.currency-list li.selected a {
        cursor: default;
      }

      ul.currency-list li a.currency-selection {
        position: relative;
        display: inline-block;
        width: 100%;
      }

      ul.currency-list li .loading {
        position: absolute;
        right: -5px;
        top: 2px;
        width: 20px;        /* the size */
        height: 20px;
        padding: 5px;       /* the border thickness */
        background: #0043ff; /* the color */        
        aspect-ratio: 1;
        border-radius: 50%;
        --_m: 
          conic-gradient(#0000,#000),
          linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
                mask: var(--_m);
        -webkit-mask-composite: source-out;
                mask-composite: subtract;
        box-sizing: border-box;
        animation: load 1s linear infinite;        
      }

      @keyframes load {
        to{transform: rotate(1turn)}
      }

      ul.currency-list .curSymbol {
        min-width: 20px;
        margin-right: 5px;
        color: #666;
        line-height: 20px;
      }

      ul.currency-list .curName {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  :deep(.popper #arrow::before) {
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }

  :deep(.popper:hover),
  :deep(.popper:hover > #arrow::before) {
    background-color: #fff;
  }
}



:deep(.popper) {
  background: v-bind(primaryColor);
  padding: 20px;
  border-radius: 20px;
  color: v-bind("textColor");
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  width: 350px;
  z-index: 9999 !important;
}

:deep(.popper #arrow::before) {
  background: v-bind(primaryColor);
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: v-bind(primaryColor);
}

@media (min-width: 700px) {
  
  #affiliateMain {
    position: relative;
    .navbar-affiliate {
      display: block;
      position: absolute;
      z-index: 0;      
      right: 7px;
      width: 195px;
      margin-left: 0.8em;
      .navbar-item {
        .guarantee {
          margin-top: 5px;
          width: 100px;
          line-height: 1.1;
          span {            
            font-weight: bold;
          }
        }
      }
    }
    .filter-wrapper {
      display: none;
    }
  }

  :deep(.tabs-component-tab .is-disabled) {
    display: inline-block;
  }  

  :deep(.tabs-component-tab) {    
    .mobile-view {
      display: none;
    }
    .default-label {
      display: inline-block;
    }
  }
}

@media (min-width: 1180px) {
  #affiliateMain {
    position: relative;
    .navbar-affiliate {
      right: 15px;
      width: 287px;      
      .navbar-item {
        .guarantee {
          margin-top: 12px;
          width: 100%;
          span {
            font-size: 1.2em;            
          }
        }
      }

    }
  }
}
</style>
