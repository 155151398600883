/**
 *
 */

(function ($) {
  $.fn.guestbreakdown = function (options) {
    // Establish our default settings
    var settings = $.extend(
      {
        refresh: false,
        post_cb: function (id_suffix) {
          return true;
        },
        validation_cb: function (id_suffix) {
          return true;
        },
        html: "Hello, World!",
        max_rooms: 8,
      },
      options
    );

    var generateAgeSelect = function (room, child, value) {
      // console.log(room+','+child);

      var ageSelect = $("<div/>", { class: "col-xs-4 wrapper" }).append(
        $("<label/>", { class: "age" })
          .attr(
            "for",
            "homepage-provider-0-simple-room-" + room + "-child-age-" + child
          )
          .text("Child age"),
        $("<select/>", {
          class: "dropdown",
          id: "homepage-provider-0-simple-room-" + room + "-child-age-" + child,
        }).append(function () {
          var options = new Array(18);
          var $container = $("<select/>");
          $.each(options, function (key, val) {
            val = key;
            $container.append(
              val == value && value != 0
                ? $("<option/>", { value: val })
                    .attr("selected", true)
                    .text("Age " + val)
                : $("<option/>", { value: val }).text("Age " + val)
            );
          });
          return $container.html();
        })
      );

      // console.log('child='+child);
      // console.log('remainder='+(child % 3));
      if (child % 3 == 1) {
        var row = $("<div/>", { class: "row m-b-5 booking" });
        ageSelect = row.wrapInner(ageSelect);
      }

      return ageSelect;
    };

    var generateRoomData = function (room, data) {
      var roomData = $("<div/>", { class: "room-data" })
        .attr("id", "r" + room)
        .append(
          $("<div/>", { class: "room-title" }).append(
            $("<span/>", { class: "title-text" }).text("Room "),

            $("<span/>", { class: "title-counter" }).text(room + 1),

            room > 0
              ? $("<a/>", { class: "trigger-remove first-time" })
                  .attr("href", "javascript:void(0)")
                  .text("Remove")
              : $("<span/>", { class: "dummy" })
          ),

          $("<div/>", { class: "row m-b-5" }).append(
            $("<div/>", { class: "col-xs-7" }).append(
              $("<span/>", { class: "adult counter" }).text(data.adults),

              $("<label/>", { class: "oh-yeah level" }).text("Adults")
            ),

            $("<div/>", { class: "col-xs-5 mobile-no-padding" }).append(
              $("<input>", {
                class: "mobile-qty form-control text-center first-time",
              }).attr({
                type: "text",
                name: "adults_" + room,
                "data-min": 1,
                "data-max": 20,
                value: data.adults,
                readonly: "readonly",
              })
            )
          ),

          $("<div/>", { class: "row m-b-5" }).append(
            $("<div/>", { class: "col-xs-7" }).append(
              $("<span/>", { class: "child counter" }).text(data.child.count),

              $("<label/>", { class: "oh-yeah" })
                .text("Children")
                .append(
                  $("<span/>", { class: "clearfix age-range" }).text(
                    "Ages (0-17)"
                  )
                )
            ),

            $("<div/>", { class: "col-xs-5 mobile-no-padding" }).append(
              $("<input>", {
                class: "mobile-qty form-control text-center first-time",
              }).attr({
                type: "text",
                name: "child_" + room,
                "data-min": 0,
                "data-max": 12,
                value: data.child.count,
                readonly: "readonly",
              })
            )
          )
        );

      if (parseInt(data.child.length) > 0) {
        var totalChild = parseInt(data.child.count);
        for (var i = 0; i < totalChild; i++) {
          var ageSelect = generateAgeSelect(
            room,
            i + 1,
            parseInt(data.child[i])
          );
          if ((i + 1) % 3 == 1) {
            roomData.append(ageSelect);
          } else {
            var row = Math.ceil((i + 1) / 3) - 1;
            $(roomData)
              .find(".row.booking:eq(" + row + ")")
              .append(ageSelect);
          }
        }
      }

      return roomData;
    };

    var reLabelRooms = function () {
      $(".guest_breakdown")
        .find(".room-data")
        .each(function (i, room) {
          $(room)
            .find(".title-counter")
            .text(i + 1);
          $(room).attr("id", "r" + i);
          $(room)
            .find(".mobile-qty")
            .each(function (j, ele) {
              var name = $(ele).attr("name");
              var nameArr = name.split("_");
              $(ele).attr("name", nameArr[0] + "_" + i);
            });
        });
    };

    var addEventHandlers = function (id_suffix) {
      $(".guest_breakdown")
        .find(".dropdown")
        .each(function (idx, ele) {
          $(ele).on("change", function (event) {
            var value = this.value;
            console.log(value);
            var wrapper = this.closest(".room-data");
            var room = parseInt($(wrapper).attr("id").substr(1));
            var child = idx;
            var data = jQuery.parseJSON(
              $("#guest-breakdown" + id_suffix).val()
            );
            data[room].child.age[child] = parseInt(value);

            refreshData(data, id_suffix);

            return false;
          });
        });

      $(".guest_breakdown")
        .find(".mobile-qty.first-time")
        .each(function (i, ele) {
          var name = $(ele).attr("name");
          var nameArr = name.split("_");
          var spintype = nameArr[0];

          var ts = $(ele).TouchSpin({
            min: $(ele).attr("data-min"),
            max: $(ele).attr("data-max"),
            booster: false,
          });

          if ($(ele).val() == $(ele).attr("data-min"))
            $(ele)
              .closest(".input-group.bootstrap-touchspin")
              .find(".bootstrap-touchspin-down")
              .addClass("disabled");
          if ($(ele).val() == $(ele).attr("data-max"))
            $(ele)
              .closest(".input-group.bootstrap-touchspin")
              .find(".bootstrap-touchspin-up")
              .addClass("disabled");

          ts.on("change", function () {
            var element = $(this);
            var value = element.val();

            var plugin = element.closest(".input-group.bootstrap-touchspin");
            $(plugin)
              .find(".bootstrap-touchspin-down")
              .toggleClass("disabled", value == element.attr("data-min"));
            $(plugin)
              .find(".bootstrap-touchspin-up")
              .toggleClass("disabled", value == element.attr("data-max"));

            var wrapper = element.closest(".room-data");
            var room = parseInt($(wrapper).attr("id").substr(1));
            var data = jQuery.parseJSON(
              $("#guest-breakdown" + id_suffix).val()
            );
            // console.log(spintype);
            switch (spintype) {
              case "adults":
                data[room].adults = parseInt(value);
                $(wrapper).find(".adult.counter").text(value);
                break;
              case "child":
                var existing = $(wrapper).find(".dropdown").length;
                // console.log(value + ' vs ' + existing);
                data[room].child.count = parseInt(value);
                if (value > existing) {
                  // console.log('calling generateAgeSelect with: '+room+','+value);
                  ageSelect = generateAgeSelect(room, value, 0);
                  if (value % 3 == 1) $(wrapper).append(ageSelect);
                  else {
                    var row = Math.ceil(value / 3) - 1;
                    $(wrapper)
                      .find(".row.booking:eq(" + row + ")")
                      .append(ageSelect);
                  }
                  addEventHandlers(id_suffix);
                  if (value == 1) data[room].child.age = [];
                  data[room].child.age.push(0);
                } else {
                  // < existing
                  var row = Math.ceil(existing / 3) - 1;
                  var position = existing % 3;
                  // console.log('position='+position);
                  if (position == 1)
                    $(wrapper)
                      .find(".row.booking:eq(" + row + ")")
                      .remove();
                  else
                    $(wrapper)
                      .find(".dropdown:eq(" + (existing - 1) + ")")
                      .closest(".wrapper")
                      .remove();
                  data[room].child.age.splice(value, 1);
                }
                $(wrapper).find(".child.counter").text(value);
                break;
            }
            refreshData(data, id_suffix);
          });

          $(ele).removeClass("first-time");
        });

      $(".guest_breakdown")
        .find("a.trigger-remove.first-time")
        .each(function (i, ele) {
          $(ele).on("click", function (e) {
            e.stopPropagation();
            var roomData = $(e.target).closest(".room-data");
            var room = parseInt($(roomData).attr("id").substr(1));
            var totalAdults = 1,
              totalChild = 0;
            $(roomData)
              .find(".mobile-qty")
              .each(function (i, input) {
                if ($(input).attr("name").startsWith("adults"))
                  totalAdults = $(input).val();
                else if ($(input).attr("name").startsWith("child"))
                  totalChild = $(input).val();
              });
            var data = jQuery.parseJSON(
              $("#guest-breakdown" + id_suffix).val()
            );
            data.splice(room, 1);
            refreshData(data, id_suffix);
            $(roomData).remove();
            reLabelRooms();
          });

          $(ele).removeClass("first-time");
        });
    };

    var createHtml = function (data, id_suffix) {
      // console.log('INSIDE createHtml');
      // console.log('id_suffix='+id_suffix);
      var gui = $("<div/>", { class: "container-fluid clearfix" });
      for (var i = 0; i < data.length; i++) {
        var roomData = generateRoomData(i, data[i]);
        gui.append(roomData);
      }

      if (!id_suffix.length)
        gui.append(
          $("<div/>", { class: "room-add" }).append(
            $("<a/>", { class: "add-text trigger-room first-time" })
              .attr("href", "javascript:void(0)")
              .text("Add another room")
          )
        );

      return gui;
    };

    var getAdultsCount = function (data) {
      var totalAdults = 0;
      for (var i = 0; i < data.length; i++)
        totalAdults += parseInt(data[i].adults);

      return totalAdults;
    };

    var getChildCount = function (data) {
      // console.log("INSIDE getChildCount");
      // console.log(data);
      var totalChild = 0;
      for (var i = 0; i < data.length; i++) {
        // console.log('i='+i);
        // console.log(data[i]);
        totalChild += parseInt(data[i].child.count);
      }

      return totalChild;
    };

    var setFieldValue = function (data) {
      // console.log('INSIDE setFieldValue');
      // console.log(data);
      var text = "";
      if (data.length > 1 && data.length <= settings.max_rooms)
        text += data.length + " Rooms: ";
      else if (data.length > settings.max_rooms)
        text += data.length + "+ Rooms: Unknown # of guests";
      if (data.length <= settings.max_rooms) {
        var totalAdults = getAdultsCount(data);
        var totalChild = getChildCount(data);
        console.log("total Children=", totalChild);
        text +=
          totalAdults +
          " Adult" +
          (totalAdults == 1 ? "" : "s") +
          ", " +
          totalChild +
          " Child" +
          (totalChild == 1 ? "" : "ren");
      }
      return text;
    };

    var refreshData = function (data, id_suffix) {
      // console.log('INSIDE refreshData');
      // console.log('id_suffix='+id_suffix);
      $("#guest-options" + id_suffix).val(setFieldValue(data));
      $("#guest-breakdown" + id_suffix).val(JSON.stringify(data));

      if (settings.refresh) {
        // console.log('Refresh?');
        // console.log($('#guest-breakdown'+id_suffix).data('pre'));
        // console.log($('#guest-breakdown'+id_suffix).val());
        var refresh =
          $("#guest-breakdown" + id_suffix).data("pre") !=
          $("#guest-breakdown" + id_suffix).val();
        $("#guest-options" + id_suffix)
          .closest(".input-wrapper")
          .toggleClass("refresh", refresh);
        $("#guest-options" + id_suffix).toggleClass("refresh", refresh);
      }
    };

    return this.each(function () {
      var $this = $(this);

      $this.id_suffix = "";
      var idArr = $this.attr("id").split("_");
      if (idArr.length == 2) {
        var id = parseInt(idArr[1]);
        $this.id_suffix = "_" + id;
      }

      if (settings.refresh) {
        var wrapper = $("<div/>", { class: "input-wrapper" });
        $this.wrap(wrapper);
        // $('#guest-breakdown').data('pre', $('#guest-breakdown').val());
      }
      // console.log(settings.breakdown);
      var data = null;
      if (typeof settings.breakdown == "undefined") {
        data = jQuery.parseJSON($("#guest-breakdown" + $this.id_suffix).val());
      } else {
        data = jQuery.parseJSON(settings.breakdown);
        $("#guest-options" + $this.id_suffix).val(setFieldValue(data));
      }
      // console.log('id_suffix='+$this.id_suffix);
      var html = createHtml(data, $this.id_suffix);

      // console.log(data);

      $this
        .tooltip({
          trigger: "manual",
          title: html.html(),
          template:
            '<div class="tooltip guest_breakdown" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner large"></div></div>',
          html: true,
          placement: "bottom",
        })
        .on("show.bs.tooltip", function () {
          // console.log('id suffix='+$this.id_suffix);
          var data = jQuery.parseJSON(
            $("#guest-breakdown" + $this.id_suffix).val()
          );
          var html = createHtml(data, $this.id_suffix);
          $(this).attr("data-original-title", html.html());

          if (settings.refresh)
            $("#guest-breakdown" + $this.id_suffix).data(
              "pre",
              $("#guest-breakdown" + $this.id_suffix).val()
            );
        })
        .on("shown.bs.tooltip", function () {
          $(this).removeAttr("data-original-title");
          $(".guest_breakdown")
            .find("a.trigger-room.first-time")
            .each(function (i, ele) {
              $(ele).on("click", function (e) {
                var rooms = $(".guest_breakdown").find(".room-data").length;
                if (rooms == settings.max_rooms) {
                  $("#guest-options" + $this.id_suffix).tooltip("hide");
                  return false;
                }
                var roomData = generateRoomData(rooms, {
                  adults: 1,
                  child: [],
                });
                $(roomData).insertBefore(
                  $(".guest_breakdown").find(".room-add")
                );
                var data = jQuery.parseJSON(
                  $("#guest-breakdown" + $this.id_suffix).val()
                );
                data.push({ adults: 1, child: [] });
                // console.log(data);

                $("#search-widget").trigger("sticky-update");

                refreshData(data, $this.id_suffix);
                addEventHandlers($this.id_suffix);
              });

              $(ele).removeClass("first-time");
            });

          addEventHandlers($this.id_suffix);
        })
        .on("hidden.bs.tooltip", function (e) {
          var $input = $(this);
          var form = $input.closest("form");

          // console.log('id suffix='+$this.id_suffix);
          var $guestBreakdown = $("#guest-breakdown" + $this.id_suffix);
          if ($guestBreakdown.val() != $guestBreakdown.data("pre")) {
            if (settings.validation_cb($this.id_suffix)) {
              $guestBreakdown.data("pre", $guestBreakdown.val()); // update the pre data

              settings.post_cb($this.id_suffix);

              if (settings.refresh) {
                $input.closest(".input-wrapper").toggleClass("refresh", false);
                $input.toggleClass("refresh", false);
              }
            }
          }
        });

      $this.click(function (e) {
        e.stopPropagation();
        var $input = $(this);
        $input.tooltip("toggle");
      });

      $(window).resize(function (event) {
        if ($(".guest_breakdown.tooltip").length)
          $("#guest-options" + $this.id_suffix).tooltip("recalculatePosition");
      });

      $("body").click(function (e) {
        if (
          $(".guest_breakdown.tooltip").length &&
          !$(e.target).closest(".guest_breakdown.tooltip").length
        ) {
          $("#guest-options" + $this.id_suffix).tooltip("hide");
        }
      });

      $.fn.resetSearchQuery = function (values) {
        // console.log(values);
        for (var i in values) {
          if (
            i.startsWith("room.") ||
            i == "rooms" ||
            i == "adults" ||
            i == "child"
          ) {
            delete values[i];
          }
        }
        return values;
      };

      $.fn.updateSearchQuery = function (guests_json, values) {
        console.log("INSIDE updateSearchQuery");
        console.log(guests_json);
        if (guests_json.length != 1) {
          var r = guests_json.length;
          if (values instanceof Array)
            values.push(encodeURIComponent("rooms") + "=" + r);
          else values["rooms"] = r;
          if (r < settings.max_rooms) {
            for (i = 0; i < r; i++) {
              var sffx = i == 0 ? "" : i;
              if (guests_json[i].adults != 1) {
                if (values instanceof Array)
                  values.push(
                    encodeURIComponent("room." + i + ".adults") +
                      "=" +
                      encodeURIComponent(guests_json[i].adults)
                  );
                else values["room." + i + ".adults"] = guests_json[i].adults;
              }
              if (guests_json[i].child.length != 0) {
                var cc = parseInt(guests_json[i].child.length);
                for (j = 0; j < cc; j++) {
                  // if (guests_json[i].child.age[j] != 0) {
                  if (values instanceof Array)
                    values.push(
                      encodeURIComponent("room." + i + ".child." + j) +
                        "=" +
                        encodeURIComponent(guests_json[i].child[j])
                    );
                  else
                    values["room." + i + ".child." + j] =
                      guests_json[i].child[j];
                  // }
                }
              }
            }
          }
        } else {
          // 1 room
          if (guests_json[0].adults != 1) {
            if (values instanceof Array)
              values.push(
                encodeURIComponent("adults") +
                  "=" +
                  encodeURIComponent(guests_json[0].adults)
              );
            else values["adults"] = guests_json[0].adults;
          } else {
            delete values["adults"];
          }
          if (guests_json[0].child.count != 0) {
            var cc = parseInt(guests_json[0].child.count);
            for (j = 0; j < cc; j++) {
              if (values instanceof Array)
                values.push(
                  encodeURIComponent("age." + j) +
                    "=" +
                    encodeURIComponent(guests_json[0].child.age[j])
                );
              else values["child." + j] = guests_json[0].child.age[j];
            }
          }
        }
        // console.log(values);
        return values;
      };

      $.fn.tooltip.Constructor.prototype.recalculatePosition = function () {
        // console.log('INSIDE recalc');
        var $tip = this.tip();
        if ($tip.is(":visible")) {
          var placement =
            typeof this.options.placement == "function"
              ? this.options.placement.call(this, $tip[0], this.$element[0])
              : this.options.placement;

          var autoToken = /\s?auto?\s?/i;
          var autoPlace = autoToken.test(placement);
          if (autoPlace) placement = placement.replace(autoToken, "") || "top";

          $tip.addClass(placement);

          var pos = this.getPosition();
          var actualWidth = $tip[0].offsetWidth;
          var actualHeight = $tip[0].offsetHeight;

          if (autoPlace) {
            var orgPlacement = placement;
            var viewportDim = this.getPosition(this.$viewport);

            placement =
              placement == "bottom" &&
              pos.bottom + actualHeight > viewportDim.bottom
                ? "top"
                : placement == "top" && pos.top - actualHeight < viewportDim.top
                ? "bottom"
                : placement == "right" &&
                  pos.right + actualWidth > viewportDim.width
                ? "left"
                : placement == "left" &&
                  pos.left - actualWidth < viewportDim.left
                ? "right"
                : placement;

            $tip.removeClass(orgPlacement).addClass(placement);
          }

          var calculatedOffset = this.getCalculatedOffset(
            placement,
            pos,
            actualWidth,
            actualHeight
          );
          this.applyPlacement(calculatedOffset, placement);
        }
      };
    });
  };
})(jQuery);
