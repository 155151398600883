<template>     		 
	<div class="row">
	    <div class="col-md-12">
		    <div class="modal-header">
				<button type="button" class="bootbox-close-button close" data-dismiss="modal" aria-hidden="true" @click="$emit('close')">x</button>
				<h4 class="m-t-0 m-b-0">Popular Rate Plan Suggestions</h4> 
			</div>							 
			<table class="table table-striped">
				<thead>
					<tr>
				      <th scope="col">Name</th>
				      <th scope="col">Rate Mgmt.</th>
				      <th scope="col">Room Types</th>
				      <th scope="col">Inclusions</th>			      
				      <th scope="col">More Options</th>
				      <th scope="col">Actions</th>
				    </tr>
			    </thead>				
			    <tbody>
			    	<tr v-for="(rp, loop) in suggestions" :key="loop" >
			    		<td>{{ rp.name }}</td>			    		
			    		<td>&nbsp;</td>
			    		<td>All</td>
			    		<td>&nbsp;</td>
			    		<td>&nbsp;</td>
			    		<td>Select | Edit</td>
			    	</tr>
			    </tbody>
			</table>   
	    </div>
	</div>   	   
</template>

<script>
    export default {
    	name: 'SuggestionModal',
        mounted() {
            console.log('Modal mounted.');
        },
        data() {
        	return {
      			suggestions: jdata.suggestions,
    		};        	
        },
        methods: {
        	
        }
    }
</script>
