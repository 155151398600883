<template>
  <iframe-zoid
    style="width: 100%; height: 800px"
    v-bind="iframeProps"    
    :onSelection="onSelection"
  />
</template>

<script>
import { create } from "../api/zoid.frameworks.js";
import { toRaw } from "vue";

export default {
  name: "AffiliateRoomCreate",
  props: {
    componentId: {
      type: String,
      required: true,
    },
    locationSlug: {
      type: String,
      required: true,
    },
    uid: {
      type: String,
      required: true,
    },
    checkin: {
      type: String,
      required: false,
    },
    checkout: {
      type: String,
      required: false,
    },
    room: {
      type: Object,
      required: false,
    },
    currency: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      iframeProps: {},
      action: "render",
    };
  },
  methods: {
    onSelection: function (data) {
      console.log("INSIDE AffiliateRoomCreate:onSelection", data);
      console.log(window.xprops);
      console.log(data.service);
      data.booking = this.transformServiceToBooking(data.service);
      console.log(data.booking);
      delete data["service"];
      this.emitter.emit("iframe-book-event", { eventContent: data });
    },
    transformServiceToBooking: function (services) {
      console.log("INSIDE transform", services);
      var bookings = [];
      var dotified = this.dotify(services);
      console.log(dotified);
      var objectKeys = Object.keys(dotified);
      console.log(objectKeys);
      const matches = objectKeys.filter((element) => {
        console.log(element);
        if (element.includes("service_qty")) {
          return true;
        }
      });
      console.log(matches);
      for (let serviceQty of matches) {
        console.log(serviceQty);
        console.log(dotified[serviceQty]);
        if (parseInt(dotified[serviceQty]) !== 0) {
          var splitName = serviceQty.split(".");
          var booking = {
            service_id: parseInt(splitName[0]),
            rateplan_id: parseInt(splitName[1]),
          };
          const propertiesToInclude = objectKeys.filter((element) => {
            console.log(element);
            return (
              element.startsWith(
                booking.service_id +
                  "." +
                  booking.rateplan_id +
                  "." +
                  splitName[2]
              ) && !element.includes("guests")
            );
          });
          for (let prop of propertiesToInclude) {
            booking[prop.split(".")[prop.split(".").length - 1]] =
              dotified[prop];
          }
          bookings.push(booking);
        }
      }
      return bookings;
    },
    dotify: function (source, pathArray, result) {
      console.log("INSIDE dotify", source, pathArray, result);
      pathArray =
        typeof pathArray === "undefined"
          ? []
          : typeof pathArray === "string"
          ? [pathArray]
          : pathArray;
      result = typeof result === "undefined" ? {} : result;

      var key, value, newKey;

      for (var i in source) {
        // eslint-disable-next-line no-prototype-builtins
        if (source.hasOwnProperty(i)) {
          key = i;
          value = source[i];

          pathArray.push(key);

          if (typeof value === "object" && value !== null) {
            result = this.dotify(value, pathArray, result);
          } else {
            newKey = pathArray.join(".");
            result[newKey] = value.toString();
          }

          pathArray.pop();
        }
      }
      return result;
    },
  },
  created() {
    console.log("INSIDE AffiliateRoomCreate created", toRaw(this.$options));
    console.log(this.guests);
    this.iframeProps.locationSlug = this.locationSlug;
    this.iframeProps.uid = this.uid;
    this.iframeProps.componentId = this.componentId;
    if (this.checkin && this.checkout) {
      this.iframeProps.checkin = this.checkin;
      this.iframeProps.checkout = this.checkout;
    }
    if (this.room) {
      this.iframeProps.room = this.room;
    }
    this.iframeProps.action = this.action;
    this.iframeProps.currency = this.currency;
    console.log(this.iframeProps);
    this.$options.components = [];
    this.$options.components["iframe-zoid"] = 
       create({
        dimensions: {
          width: "100%",
          height: "800px",
        },
        tag: "affiliate-room-" + this.componentId + "-component",
        url: (params) => {
          console.log("INSIDE ZoidIframe url");
          console.log(params.props.locationSlug);
          console.log(params.props);
          return process.env.MIX_APP_URL + "/room/" + params.props.locationSlug;
        },

        props: {
          action: {
            type: "string",
            required: true,
          },
          locationSlug: {
            type: "string",
            required: true,
          },
          componentId: {
            type: "string",
            required: true,
            queryParam: true,
          },
          uid: {
            type: "string",
            required: true,
            queryParam: true,
          },
          checkin: {
            type: "string",
            required: false,
            queryParam: true,
          },
          checkout: {
            type: "string",
            required: false,
            queryParam: true,
          },
          room: {
            type: "object",
            queryParam: true,
            serialization: "dotify",
          },
          currency: {
            type: "string",
            required: false,
          },
        },
      })
      .driver("vue3");
  },
  mounted() {
    const vm = this;
    this.emitter.on("affiliate-room-" + this.componentId + "-event", (evt) => {
      console.log("INSIDE affiliate-room-" + vm.componentId + "-event");
      console.log(evt.eventContent);
      vm.action = evt.eventContent.action;
      vm.iframeProps.action = vm.action; // Update the action
      if (evt.eventContent.action === 'update' && 
          evt.eventContent.field === 'currency') {        
        vm.iframeProps["currency"] = evt.eventContent.value;                
      }
    });
  },
  beforeUnmount() {
    console.log("INSIDE BEFORE UNMOUNT", this.componentId);
  },
  unmounted() {
    console.log("INSIDE UNMOUNTED", this.componentId);
    // destroy();
  },
};
</script>
