/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Laravel = {
  csrfToken:
    document.head.querySelector("[name=csrf-token]") != null
      ? document.head.querySelector("[name=csrf-token]").content
      : null,
};
require("./bootstrap");

import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;

require("../../../public/bower_resources/bootstrap-touchspin/dist/jquery.bootstrap-touchspin");
require("../../../public/js/jquery.guestbreakdown");

import { createApp } from "vue/dist/vue.esm-bundler.js";
import mitt from 'mitt';
import PrimeVue from "primevue/config";
import moment from "moment";

const app = createApp({});
// import * as zoid from "zoid/dist/zoid.js";
// import * as pragmatic from "jsx-pragmatic/dist/jsx-pragmatic.js";

// window.zoid = zoid;
// window.pragmatic = pragmatic;

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import ImageSlider from "./components/ImageSlider.vue";
import ReviewsDisplay from "./components/ReviewsDisplay.vue";
import ModalLink from "./components/ModalLink.vue";
import SuggestionModal from "./components/SuggestionModal.vue";
import AffiliateMain from "./components/AffiliateMain.vue";
import AffiliateSearch from "./components/AffiliateSearch";
import GuestBreakdown from "./components/GuestBreakdown";
// import AffiliateRooms from "./components/AffiliateRooms";
import AffiliateRoomsCreate from "./components/AffiliateRoomsCreate";
import AffiliateRoomCreate from "./components/AffiliateRoomCreate";

import "primeicons/primeicons.css"; // icons

app
  .component("image-slider", ImageSlider)
  .component("reviews-display", ReviewsDisplay)
  .component("modal-link", ModalLink)
  .component("suggestion-modal", SuggestionModal)
  .component("affiliate-main", AffiliateMain)
  .component("affiliate-search", AffiliateSearch)
  .component("guest-breakdown", GuestBreakdown)
  .component("affiliate-rooms-create", AffiliateRoomsCreate)
  .component("affiliate-room-create", AffiliateRoomCreate);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$window = window;

app.use(PrimeVue);

const cond = document.getElementById("app") || false;
if (cond) {
  app.mount("#app");
}
