<script>
import SuggestionModal from "./SuggestionModal.vue";

export default {
  components: {
    SuggestionModal,
  },
  mounted() {
    console.log(document.getElementById("app"));
    if (jdata && jdata.suggestions && jdata.suggestions.length)
      this.text = document.getElementById("app").getAttribute("data-text");
  },
  data() {
    return {
      text: "",
    };
  },
  methods: {
    show() {
      // this.$modal.show('hello-world');
      this.$modal.show(SuggestionModal, {}, { width: "75%" });
    },
  },
  template:
    '<a id="modal-link" class="btn btn-link" @click="show">{{ text }}</a>',
};
</script>
