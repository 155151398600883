<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-md-7">
        <div class="row">
          <div class="col-xs-12 col-md-6 padding-lr-8">
            <AutoComplete
              placeholder="Where to?"
              optionLabel="name"
              v-model="selectedLocation"
              :suggestions="filteredLocations"
              @complete="searchLocations($event)"
              @itemSelect="locationSelect($event)"
              @change="locationChange($event)"
              @beforeShow="beforeShow($event)"
            >
              <template #item="slotProps">
                <img :alt="slotProps.item.name" :src="slotProps.item.icon" />
                <span
                  class="easy-autocomplete-text ellipsis"
                  :title="slotProps.item.name.replace(/<[^>]+>/g, '')"
                >
                  {{ slotProps.item.name }}
                </span>
              </template>
            </AutoComplete>
          </div>
          <div class="col-xs-12 col-md-6 padding-lr-8">
            <div class="row margin-lr-0 m-b-15">
              <Datepicker
                v-model="formData.dateRange"
                range
                autoApply
                :partialRange="false"
                multiCalendars
                noToday
                utc="true"
                :enableTimePicker="false"
                :minDate="new Date()"
                :maxDate="new Date(Date.now() + 358 * 24 * 60 * 60 * 1000)"
                preventMinMaxNavigation
                :format="customFormat"
                @open="handleOpenEvent"
                @internalModelChange="handleInternalEvent"
                @cleared="handleClearedEvent"
                @closed="handleClosedEvent"
                @invalidSelect="handleInvalidSelect"
              >
                <template #input-icon>
                  <img
                    class="input-slot-image"
                    src="/resource/images/icon-calendar.png"
                  />
                </template>
                <template #clear-icon="{ clear }">
                  <img
                    class="input-slot-image"
                    src="/resource/images/icon-x.png"
                    @click="clear"
                  />
                </template>
                <template #dp-input="{ value }">
                  <div id="lightpick">
                    <div class="col-xs-6 col-md-6 no-padding">
                      <input
                        name="checkin"
                        ref="checkin"
                        id="accom_date_picker_one"
                        type="text"
                        placeholder="Check-in"
                        class="form-control border-select lightpick-input divider"
                        value=""
                        data-rule-dateBR="true"
                        data-rule-greaterOrEqualTo="today"
                        readonly="readonly"
                      />
                    </div>
                    <div class="col-xs-6 col-md-6 no-padding">
                      <input
                        name="checkout"
                        ref="checkout"
                        id="accom_date_picker_two"
                        type="text"
                        placeholder="Check-out"
                        class="form-control border-select lightpick-input input-clear"
                        data-rule-dateBR="true"
                        value=""
                        readonly="readonly"
                      />
                    </div>
                    <div style="display: none">
                      <input :value="value" type="text" readonly />
                    </div>
                  </div>
                </template>
              </Datepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-md-5">
        <div class="row">
          <div class="col-xs-6 col-md-7 padding-lr-8">
            <guest-breakdown v-once></guest-breakdown>
          </div>
          <div class="col-xs-6 col-md-5 padding-lr-8">
            <a
              href="javascript:void(0)"
              id="affiliate-search"
              class="btn btn-info btn-block"
              :style="
                primaryColor
                  ? {
                      backgroundColor: this.primaryColor,
                      borderColor: this.primaryColor,
                    }
                  : ''
              "
            >
              Search
            </a>
            <div class="powered-by">
              <span>Powered by</span>
              <img
                :src="baseUrl + '/resource/images/logo_smartie.png'"
                alt="Smartie logo"
                class="logo-smartie"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isProxy, toRaw } from "vue";
import AutoComplete from "primevue/autocomplete";
import Datepicker from "@vuepic/vue-datepicker";
import GuestBreakdown from "./GuestBreakdown.vue";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "AffiliateSearch",
  components: {
    AutoComplete,
    Datepicker,
    GuestBreakdown,
  },
  props: {
    primaryColor: String,
  },
  data() {
    return {
      baseUrl: process.env.MIX_APP_URL,
      formData: {
        locationSlug: null,
        dateRange: null,
      },
      tempDateRange: null,
      filteredLocations: null,
      selectedLocation: null,
      clearElement: null,
      firstSelection: false,
      prefilledDateRange: false,
    };
  },
  created() {},
  mounted() {
    console.log("AFFILIATE MAIN MOUNTED!!", this.primaryColor);
    const autocompleteContainer = document.querySelector(".p-autocomplete");
    const searchNode = document.createElement("i");
    searchNode.classList.add("pi", "pi-search");
    autocompleteContainer.appendChild(searchNode);
    const clearNode = document.createElement("i");
    clearNode.classList.add("pi", "pi-times", "p-hidden-accessible");
    autocompleteContainer.appendChild(clearNode);
    this.clearElement = clearNode;
    this.clearElement.addEventListener("click", this.clearSearch);
  },
  methods: {
    locationChange() {
      this.clearElement.classList.add("p-hidden-accessible");
    },
    clearSearch() {
      this.selectedLocation = "";
      this.clearElement.classList.add("p-hidden-accessible");
    },
    beforeShow() {
      this.clearElement.classList.remove("p-hidden-accessible");
    },
    locationSelect($event) {
      this.formData.locationSlug = $event.value.slug;
      console.log(this.formData.locationSlug);
      this.clearElement.classList.remove("p-hidden-accessible");
    },
    searchLocations($event) {
      var url = "/autocomplete?phrase=" + $event.query + "&format=json";
      url += "&segment=affiliate-program";
      url += "&uid=" + "d29f88abdd883a9fa3733bb500a8c96a";
      fetch(url, {
        method: "get",
      })
        .then(async (response) => {
          let results = await response.json();
          console.log(response);
          if (response.status === 200) {
            console.log(results);
            this.filteredLocations = results.slice(0, 5);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    customFormat() {
      console.log("INSIDE customFormat");
      let format = "";
      if (isProxy(this.formData.dateRange)) {
        this.firstSelection = false;
        const dateRange = toRaw(this.formData.dateRange);
        console.log(dateRange);
        let date1 = dateRange[1] !== null ? new Date(dateRange[0]) : null;
        let date2 = dateRange[1] !== null ? new Date(dateRange[1]) : null;
        if (date1 !== null) {
          let day = date1.getDate();
          let month = date1.getMonth() + 1;
          let year = date1.getFullYear();
          var date1Format = `${day}/${month}/${year}`;
          this.$refs.checkin.value = date1Format;
          format += date1Format;
        }
        if (date2 !== null) {
          if (+date1 === +date2) {
            date2 = new Date(date2.getTime() + 60 * 60 * 24 * 1000);
          }
          let day = date2.getDate();
          let month = date2.getMonth() + 1;
          let year = date2.getFullYear();
          var date2Format = `${day}/${month}/${year}`;
          this.$refs.checkout.value = date2Format;
          format += " " + date2Format;
        }
      }
      return format;
    },
    handleOpenEvent() {      
      this.firstSelection = true;
      let currentDateRange = toRaw(this.formData.dateRange);
      this.prefilledDateRange =
        currentDateRange != null && currentDateRange.length === 2;
    },
    handleInternalEvent(dateProxy) {
      console.log("INSIDE handleInternal", dateProxy);
      console.log("current formData.dateRange", this.formData.dateRange);
      if (this.firstSelection) {
        const dateArray = toRaw(dateProxy);
        let date1 = new Date(dateArray[0]);
        console.log("INSIDE firstSelection");
        if (this.prefilledDateRange) {
          console.log("NULLing the date field value(s)");
          console.log("checkout=", this.$refs.checkout.value);
          this.$refs.checkin.value = null;
          this.$refs.checkout.value = null;
          console.log("checkout=", this.$refs.checkout.value);
          // this.formData.dateRange = [date1, null];
        }
        // this.formData.dateRange = null;
        this.firstSelection = false;
        let day = date1.getDate();
        let month = date1.getMonth() + 1;
        let year = date1.getFullYear();
        var date1Format = `${day}/${month}/${year}`;
        this.$refs.checkin.value = date1Format;
        this.tempDateRange = dateArray;
        console.log("tempDateRange is NOW:", this.tempDateRange);
      }
    },
    handleUpdateEvent() {
      console.log("INSIDE handleUpdate EVENT");
    },
    handleClearedEvent() {
      this.$refs.checkin.value = "";
      this.$refs.checkout.value = "";
      this.prefilledDateRange = false;
      this.firstSelection = false;
    },
    handleClosedEvent(param) {
      console.log(param);
      console.log("INSIDE handleClosedEvent", this.formData.dateRange);
      let currentDateRange = toRaw(this.formData.dateRange);
      let tempDateRange = toRaw(this.tempDateRange);
      let currentDate1 =
        currentDateRange && currentDateRange.length
          ? new Date(currentDateRange[0])
          : null;
      let tempDate1 =
        tempDateRange && tempDateRange.length
          ? new Date(tempDateRange[0])
          : null;
      console.log("tempDateRange", tempDateRange);
      console.log("firstSelection", this.firstSelection);
      console.log("currentDate1", currentDate1);
      console.log("tempDate1:", tempDate1);
      if (
        !this.firstSelection &&
        (currentDate1 == null || currentDate1.getTime() != tempDate1.getTime())
      ) {
        console.log("INSIDE CONDITION FOR CLOSED EVENT");
        // let date1 = this.parseDate(this.$refs.checkin.value);
        if (this.tempDateRange !== null) {
          let date1 = this.tempDateRange[0];
          console.log(date1);
          let date2 = new Date(date1.getTime() + 60 * 60 * 24 * 1000);
          let day = date2.getDate();
          let month = date2.getMonth() + 1;
          let year = date2.getFullYear();
          var date2Format = `${day}/${month}/${year}`;
          this.$refs.checkout.value = date2Format;
          console.log(this.$refs.checkout.value);
          this.formData.dateRange = [date1, date2];
        }
      }
    },
    handleInvalidSelect(dateProxy) {
      console.log("INSIDE invalid ", dateProxy);
    },
  },
};
</script>

<style scoped>
:deep(.dp__input) {
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='26' viewBox='0 0 8 18'%3E%3Cpath fill='%239599AA' fill-rule='nonzero' d='M.119.718l7.158 7.407-.033-.551-6.737 8.889a.425.425 0 0 0 .08.593.42.42 0 0 0 .59-.08l6.737-8.889a.425.425 0 0 0-.033-.551L.723.128A.42.42 0 0 0 .128.12a.425.425 0 0 0-.009.598z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 4px;
}

:deep(.dp__input_icon) {
  left: 10px;
}
:deep(.dp__clear_icon) {
  right: 10px;
}

:deep(.dp__clear_icon .input-slot-image) {
  width: 12px;
  height: 12px;
}
</style>
