<template>
  <div id="reviews-display" v-if="reviews">
    <div class="row" v-for="(review, loop) in reviews" :key="review.id">
      <div class="col-xs-12 text-center" v-if="loop == 0">
        <h4 class="latest-heading" v-if="total > 8">{{ heading }}</h4>
        <h4 class="latest-heading" v-else>&nbsp;&nbsp;</h4>
      </div>
      <div class="col-xs-12">
        <div class="reviews-box">
          <div class="row">
            <div class="col-md-11 col-sm-11">
              <div class="always-60 pull-left">
                <div class="trapezoid3">
                  <div class="reviews-excellent">
                    <div class="internal">
                      {{ Math.round(review.score / 10, 1) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="check-icon">
                {{ review.summary }}&nbsp;&nbsp;<span class="reviews-date">
                  {{ $moment(review.submitted_on).format("MMMM, YYYY") }}</span
                >
              </div>
            </div>
          </div>

          <div class="row m-t-5 padding-l-70">
            <div class="col-md-11 col-sm-11">
              <div
                class="reviews-text m-b-5"
                v-if="
                  review.likes_field.length > 0 ||
                  review.dislikes_field.length > 0
                "
              >
                <div v-if="review.likes_field.length > 0">
                  <i class="fa fa-thumbs-up"></i
                  ><span class="padding-l-10">{{ review.likes_field }}</span>
                </div>
                <div v-if="review.dislikes_field.length > 0">
                  <i class="fa fa-thumbs-down"></i
                  ><span class="padding-l-10">{{ review.dislikes_field }}</span>
                </div>
              </div>
              <div
                class="reviews-text m-b-5"
                v-if="
                  supervisor == 1 &&
                  (review.likes.length > 0 || review.dislikes.length > 0)
                "
              >
                <div v-if="review.likes.length > 0">
                  <i class="fa fa-smile-o"></i
                  ><span class="padding-l-10">"{{ review.likes }}"</span>
                </div>
                <div v-if="review.dislikes.length > 0">
                  <i class="fa fa-frown-o"></i
                  ><span class="padding-l-10">"{{ review.dislikes }}"</span>
                </div>
              </div>
              <span v-if="review.boundary_id != null">
                <span
                  class="flag-icon"
                  :class="['flag-icon-' + review.boundary.code.toLowerCase()]"
                ></span
                >&nbsp;<span class="small explore_gray">{{
                  review.boundary.association.name
                }}</span
                >&nbsp;&nbsp;
              </span>
              <span v-else>
                <span class="small explore_gray">Unknown</span>&nbsp;&nbsp;
              </span>
              <span class="icon">
                <i
                  v-for="(class_name, index) in review.traveler_icons"
                  :key="index"
                  :class="[class_name]"
                ></i>
              </span>
              <span class="small explore_gray">{{ review.traveler_type }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // console.log('MOUNTED');
    this.init();
  },
  props: {
    dataid: Number,
  },
  data() {
    return {
      base_url: window.location.origin + "/reviews/load/",
      reviews: [],
      heading: "",
      total: 0,
      supervisor: 0,
      error: null,
    };
  },
  methods: {
    init() {
      this.loadData();
    },
    loadData() {
      let ajax_url = this.base_url + this.dataid;
      console.log(ajax_url);
      fetch(ajax_url, {
        method: "get",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Accept-Language": "en",
        },
      })
        .then(async (response) => {
          let result = await response.json();
          if (result.success) {
            this.reviews = result.reviews;
            this.total = result.total;
            this.heading = result.heading;
            this.supervisor = result.supervisor;
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },
  },
};
</script>
