<template>
  <div class="loading-wrapper">
    <div
      v-show="!clearedLoading"
      class="loading-container flex items-center justify-center opacity-100"
    >
      <loader :active="!clearedLoading" :message="loadingMessage"></loader>
    </div>      
    <iframe-zoid
      style="width: 100%; height: 800px"
      :locationSlug="locationSlug"
      :uid="uid"
      :facilityId="facilityId"
      :tabs="tabs"
      :message="message"
      :currency="currency"
      :filters="filters"
      :onSelection="onSelection"
      :onSearchResultsLoaded="onSearchResultsLoaded"
      :onFiltersClose="onFiltersClose"
    />
  </div>
</template>

<script>
import * as zoid from "../api/zoid.frameworks.js";
import Loader from "./Loader.vue";
import { toRaw } from "vue";
import { createToaster } from "@meforma/vue-toaster";

export default {
  name: "AffiliateRoomsCreate",
  components: {
    Loader,
  },
  props: {
    locationSlug: {
      type: String,
      required: true,
    },
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      clearedLoading: true,
      loadingMessage: "Updating the currency amounts ...",
      userSelectedRoom: false,
      roomSlug: "",
      urlParams: null,
      facilityId: 0,
      toaster: null,
      toastOptions: {
        position: "top-left",
      },
      tabs: 0,
      currency: "",
      filters: false,
      message: "",
    };
  },
  methods: {
    onSelection: function (data) {
      this.roomSlug = data.slug;
      this.urlParams = data.urlParams;
      this.facilityId = data.facilityId;
      this.userSelectedRoom = true;
      this.currency = data.currency;
      console.log("INSIDE AffiliateRoomsCreate:onSelection", data);
      console.log(window.xprops);
      this.emitter.emit("iframe-search-event", { eventContent: data });
    },
    onSearchResultsLoaded: function (data) {
      console.log("onSearchResultsLoaded !!!", data);
      if ("currency" in data !== false && data.currency !== this.currency) {
        this.currency = data.currency;
      }
      if ("slug" in data !== false && this.roomSlug !== data.slug) {
        this.roomSlug = data.slug;
        if ("name" in data !== false) {
          this.emitter.emit("iframe-search-event", { eventContent: { action: 'update', field: 'dynamicLabel', value: data.name } });
        }
      }
      this.clearedLoading = true;
    },
    // No longer used (below)
    onFiltersClose: function () {
      this.filters = false;
      this.emitter.emit("iframe-search-event", { eventContent: { action: 'update', field: 'filterOpen', value: this.filters } });
    },
  },
  created() {
    console.log("INSIDE AffiliateRoomsCreate", toRaw(this.$options));
    console.log("locationSlug=", this.locationSlug);
    // this.$options.components = [];
    this.$options.components["iframe-zoid"] = zoid
      .create({
        dimensions: {
          width: "100%",
          height: "800px",
        },
        tag: "affiliate-rooms-component",
        url: (params) => {
          console.log("INSIDE AffiliateRoomsCreate url");
          console.log(params.props);
          return (
            process.env.MIX_APP_URL + "/rooms" + "/" + params.props.locationSlug
          );
        },

        props: {
          locationSlug: {
            type: "string",
            required: true,
          },

          uid: {
            type: "string",
            required: true,
            queryParam: true,
          },

          facilityId: {
            type: "number",
            required: false,
          },

          tabs: {
            type: "number",
            required: true,
          },

          currency: {
            type: "string",
            required: false,
          },

          filters: {
            type: "boolean",
            required: true,
            default: false,
          },

          message: {
            type: "string",
            required: false,
          },

          onSelection: {
            type: "function",
          },

          onSearchResultsLoaded: {
            type: "function",
          },
        },
      })
      .driver("vue3");
  },
  mounted() {
    const vm = this;
    this.toaster = createToaster(this.toastOptions);
    this.emitter.on("affiliate-rooms-component", (evt) => {
      console.log("INSIDE AffiliateRoomsCreate", evt);
      if ("facilityId" in evt.eventContent !== false) {
        vm.facilityId = evt.eventContent.facilityId;
      }
      vm[evt.eventContent.field] = evt.eventContent.value;
      if (evt.eventContent.field === "currency") {
        vm.clearedLoading = false;
      }
      setTimeout(function () {
        if (evt.eventContent.field === "message") {
          vm[evt.eventContent.field] = "";
        }
      }, 1000);      
    });
  },
};
</script>
<style scoped>

  .loading-wrapper {
    height: 100vh;
    position: relative;
  }

  .loading-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: white;
  }
</style>
