<template>
<input
  readonly="readonly"
  ref="guestOptions"
  class="form-control"
  id="guest-options"
  value="1 Adult, 0 Children"
/>
<input
  type="hidden"
  id="guest-breakdown"
  ref="guestBreakdown"
  name="guests"
  value='[{"adults":1,"child":{"count":0}}]'
/>    
</template>

<script>
import { ref } from "vue";

export default {
  name: "GuestBreakdown",
  setup() {
    const guestOptions = ref(null);
    const guestBreakdown = ref(null);
    return {
      guestOptions,
      guestBreakdown
    };
  },
  mounted() {
    console.log(this.guestBreakdown.value);
    $("#guest-options").guestbreakdown({
        breakdown: this.guestBreakdown.value,
        max_rooms: 5,
    });
  },
}
</script>
