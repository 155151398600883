<template>
  <div id="image-slider">
    <div class="heading">{{ app_name }} Trusted Partners</div>
    <transition-group tag="ul" name="list-partners">
      <li
        v-for="partner in partners"
        v-bind:key="partner.id"
        class="list-partners-item"
      >
        <a
          @mouseover="cancelRotate"
          @mouseleave="resumeRotate(5000)"
          :href="
            '/' +
            partner.pagetopic.parent.association.identifier +
            '/' +
            partner.association.identifier
          "
          :title="partner.association.name"
        >
          <div
            class="logo"
            :style="{
              'background-image': 'url(/uploads/partners/logos/' + partner.logo,
            }"
          ></div>
        </a>
      </li>
    </transition-group>
  </div>
</template>

<style>
/* global styles */
#image-slider .heading {
  font-size: 16px;
  text-align: center;
  margin: 0;
  border-top: 1px solid #ccc;
  padding: 15px 0;
}
#image-slider ul {
  margin: 10px 0 20px -170px;
  list-style: none;
  padding: 0;
  display: flex;
  overflow: hidden;
}
#image-slider .logo {
  width: 120px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center 50%;
}
/* Transition styles */
#image-slider .list-partners-item {
  transition: all 2s;
  display: inline-block;
  margin: 0 25px;
}
#image-slider .list-partners-leave-to {
  opacity: 0;
  transform: translateX(-170px);
}
#image-slider .list-partners-enter {
  transform: translateX(170px);
}
#image-slider .list-partners-leave-active {
  position: absolute;
}

@media only screen and (min-width: 768px) {
  #image-slider ul {
    margin: 10px 0 20px -190px;
  }

  #image-slider .list-partners-item {
    margin: 0 45px;
  }
}
</style>

<script>
export default {
  mounted() {
    this.resumeRotate(5000);
  },
  data() {
    return {
      app_name: process.env.MIX_APP_NAME,
      // eslint-disable-next-line no-undef
      partners: session.partners,
      timer: 0,
      id: 1000,
    };
  },
  methods: {
    rotatePartners() {
      var leaving = this.partners.slice(0, 1);
      this.partners.splice(0, 1);
      var partner = leaving[0];
      partner.id = this.id++;
      this.partners.splice(this.partners.length, 0, partner);
    },
    resumeRotate(interval) {
      this.timer = setInterval(this.rotatePartners, interval);
    },
    cancelRotate() {
      clearInterval(this.timer);
    },
  },
  beforeUnmount() {
    this.cancelRotate();
  },
};
</script>
