<template>
  <modal :show="show" @close="close">
    <div class="modal-header">
      <h3 v-html="title" :style="{ color: titleColor }"></h3>
    </div>
    <div class="modal-body">
      <p v-html="message"></p>
    </div>
    <div class="modal-footer text-right">
      <button
        class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
        @click="close()"
      >
        OK
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from "./Modal.vue";

export default {
  name: "InfoModal",
  components: {
    Modal,
  },
  props: {
    show: Boolean,
    title: {
      type: String,
      default: "SmartPay",
    },
    titleColor: {
      type: String,
      default: "#000",
    },
    message: String,
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (this.show && e.keyCode == 27) {
        this.close();
      }
    });
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-header h3 {
  margin-top: 0;
}

.modal-body {
  margin: 0px 0;
}
</style>
