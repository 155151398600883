export const affiliateAPISettings = {
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "en",
  },
  baseURL: process.env.MIX_APP_URL,
  affiliateProgramURL: process.env.MIX_APP_URL + "/affiliate-program/",
  facilityNameURL: process.env.MIX_APP_URL + "/listing/facility/",
};

export const affiliateConfig = {
  maxAccommodationTabs: 10,
}
